@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

:root {
  --unit-100vh: 100vh;
}
@supports (height: 100dvh) {
  :root {
    --unit-100vh: 100dvh;
  }
}

body {
  position: relative;
  @apply bg-bright;
}

body.dark {
  @apply bg-dark;
}

.dumpit-scrollbar {
  @apply scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full;
  --scrollbar-thumb: #ffffff;
  --scrollbar-track: #f1f0eb;
}

.dark .dumpit-scrollbar {
  --scrollbar-thumb: #343434;
  --scrollbar-track: #242424;
}

a,
button {
  @apply focus-within:outline-0 focus-within:ring-0 focus-within:outline-none;
}

audio::-webkit-media-controls-panel {
  @apply bg-white rounded-full;
}

.Typewriter {
  @apply flex h-32 md:h-20;
}
.Typewriter__cursor,
.Typewriter__wrapper {
  @apply text-center text-6xl md:text-7xl leading-tight tracking-tighter font-bold text-gray-600;
}
